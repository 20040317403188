.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}

.social-icon {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-icon:hover {
    color: #4CAF50; /* Cambia el color al pasar el cursor, por ejemplo, a verde */
}
