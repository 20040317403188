.contacto-section {
    padding: 4rem 2rem;
    background-color: #f7f9fc;
    color: #333;
}

.contacto-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.contacto-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #4a5568;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
    outline: none;
}

.submit-button {
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.submit-button:active {
    transform: translateY(0);
}
