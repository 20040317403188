@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}

h1 {
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.5rem; /* Ajusta según tu diseño */
  letter-spacing: 3px; /* Agrega espacio entre letras si es necesario */
}

.section h1 {
  margin-top: 4rem; /* Añade espacio entre el navbar y el h1 */
}
.social-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.social-icons a {
  text-decoration: none;
}

.social-icons img {
  width: 40px; /* Ajusta el tamaño del ícono aquí */
  height: 40px;
  transition: transform 0.3s;
}

.social-icons img:hover {
  transform: scale(1.1); /* Efecto de zoom al pasar el cursor */
}

