.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Estilos de enlaces */
.navbar-links {
    list-style: none;
    display: flex;
    gap: 2rem;
}

.navbar-links a {
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: yellowgreen;
}

/* Botón de menú de hamburguesa para móviles */
.navbar-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
    }

    /* Mostrar botón de hamburguesa en móviles */
    .navbar-toggle {
        display: block;
        position: absolute;
        right: 2rem;
        top: 1.2rem;
    }

    /* Alinear enlaces a la derecha cuando el menú está abierto en móviles */
    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        align-items: flex-end; /* Alinea los enlaces a la derecha */
        padding: 1rem 2rem;
    }

    /* Mostrar enlaces cuando se activa el menú en móviles */
    .navbar-links.open {
        display: flex;
    }
}
